import React from 'react';
import { Link } from 'gatsby';
import ContentPageWrapper from '../containers/Charity/ChapterGeneral/contentPageWrapper';
import SEO from '../components/seo';
import imageChapter03 from '../common/src/assets/image/charity/specter/kapitola_03_small.jpg';

export default () => {
  return (
    <ContentPageWrapper>
      <SEO title="Tretia kapitola: Tyrania na Východe | Ako duch komunizmu vládne nášmu svetu"
           description="Tretia kapitola: Tyrania na Východe"
           image={imageChapter03}/>
      <h1>Tretia kapitola: Tyrania na Východe</h1>
      <h2>Úvod</h2>
      <p>Od doby, čo sa komunistická strana v Sovietskom zväze chopila moci, uplynulo celé storočie. Podľa záznamov
        zhromaždených americkým kongresom sú komunistické režimy zodpovedné za smrť najmenej 100 miliónov ľudí.<a
          href="#_edn1" id="_ednref1">[1]</a> Čierna kniha komunizmu popisuje túto históriu vraždenia, čerpajúc z
        dokumentov odtajnených vládami krajín bývalého Sovietskeho zväzu a východnej Európy, ako aj zo záznamov o
        obetiach komunistických politických kampaní v Číne, Severnej Kórei a ďalších komunistických krajinách.<a
          href="#_edn2" id="_ednref2">[2]</a></p>
      <p>Komunistická totalita býva často prirovnávaná k nacizmu počas druhej svetovej vojny. Aj keď medzi nimi nájdeme
        mnoho podobností, často sa prehliada jeden podstatný rozdiel: nacisti spáchali genocídu, ale cieľ komunizmu
        presahuje fyzické vyhladenie.</p>
      <p>Veriaci ľudia nepovažujú fyzickú smrť za skutočnú smrť človeka, keďže veria, že duša odíde do neba alebo sa
        znovu narodí v kolobehu reinkarnácie. Komunizmus používa zabíjanie ako nástroj na zničenie morálnych základov
        ľudstva; jeho cieľom je zabiť nielen fyzické telo, ale aj dušu.</p>
      <p>Pre komunistické režimy je bežné, že vykonávajú intenzívne politické čistky vo vlastných radoch a vyberajú tých
        najkrutejších vodcov. Pre mnoho ľudí je ťažké pochopiť logiku toho, aké barbarstvo komunistická strana uplatňuje
        voči vlastným kádrom, a to aj voči tým, ktorí sa stali jej obeťami len preto, že sa odchýlili od smerovania
        strany iba v určitých otázkach, zatiaľ čo inak boli strane a jej vedeniu úplne oddaní. Jedným z dôvodov tejto
        krutosti je to, že duch komunizmu má pri svojej vzbure proti bohu a ľudstvu neustály inštinktívny strach z toho,
        že bude čoskoro zničený. Aby si dodal síl, potrebuje jednotlivcov, ktorí z morálneho hľadiska nerozlišujú medzi
        dobrom a zlom. Títo jednotlivci sú identifikovaní svojou brutalitou počas masových vraždení a duch komunizmu ich
        vyzdvihuje na vedúce pozície v strane, aby zabezpečil zachovanie svojej pozemskej tyranie.</p>
      <p>Kádre Čínskej komunistickej strany (ČKS), ktorí sa 4. júna 1989 odmietli zúčastniť masakru študentov na námestí
        Nebeského pokoja, boli odstránené alebo bol zamedzený ich ďalší postup. Ťiang Ce-min, ktorý počas masakru
        preukázal svoju krutosť, bol povýšený na vodcu ČKS. Potom, čo Ťiang začal v roku 1999 prenasledovať Falun Gong,
        povýšil úradníkov Luo Kana a Čou Jung-kchanga na vysoké pozície, pretože&nbsp; počas prenasledovania preukázali
        schopnosť páchať tie najbrutálnejšie zločiny.</p>
      <p>Ďalší motív zabíjania je, že sa hľadajú spolupáchatelia z&nbsp;radov širokej verejnosti, podobne ako počas
        kultúrnej revolúcie. Vraždením a ďalšími zločinmi sa masy ľudí stávajú spolupáchateľmi krutostí ČKS. Tí
        najbrutálnejší páchatelia zločinov sa potom stávajú najzarytejšími stúpencami strany. Dokonca ani dnes mnoho
        bývalých členov Červených gárd, ktorí počas kultúrnej revolúcie útočili na ľudí a vraždili ich, nevyjadrili
        žiadnu ľútosť nad tým, čo v mladosti spravili.</p>
      <p>Tým, že komunistická strana svoje obete vraždí otvorene a úmyselne, udržuje širokú verejnosť v strachu a v
        poslušnosti.</p>
      <p>Počas celej histórie vládcovia a tyrani zabíjali svojich nepriateľov, kvôli pociťovanej potrebe chrániť svoju
        moc, alebo moc impéria. Komunistické strany sa však nezaobídu bez nepriateľov, a ak žiadni nepriatelia nie sú,
        vymyslia si ich, aby mohli pokračovať v zabíjaní. V krajine ako je Čína, s takou dlhou históriou a bohatou
        kultúrou, by komunizmus nemohol dosiahnuť svoje ciele bez neustáleho vraždenia. Číňania vďaka kultúrnemu
        dedičstvu piatich tisíc rokov verili v nebesia a uctievali ich. Ak by s ľuďmi ČKS brutálne nezaobchádzala, nikdy
        by sa nepodvolili jej barbarskej a bezbožnej vôli. Strana sa poučila zo svojho sovietskeho fungovania a&nbsp;za
        základný prostriedok na udržanie si moci použila masové vraždenie.</p>
      <h2>1. Vzostup totalitného komunizmu</h2>
      <p>Pretože komunizmus je stelesnením ducha komunizmu, jeho východiskový bod nemôže byť iný ako nečestný. Potom, čo
        Karl Marx vyhlásil, že „V Európe straší duch – duch komunizmu“, založili lupiči a bitkári Parížsku komúnu a
        zdevastovali francúzske hlavné mesto s jeho jedinečnými umeleckými dielami a kultúrnymi pamiatkami. V Rusku
        a&nbsp;v Číne sa komunistické strany chopili moci prostredníctvom opovrhnutiahodných činov sprisahania a
        krviprelievania.</p>
      <p>Marxistická teória a rôzne ideologické traktáty, na ktoré sa komunistické režimy odvolávajú, sú plné sľubov na
        podporu a zastupovanie záujmov proletárskych robotníkov a roľníkov. Ale v praxi bola robotnícka trieda rýchlo
        zradená a za komunizmu sa jej dostalo najhoršieho zneužívania.</p>
      <h3>a) Vzostup sovietskych komunistov k moci</h3>
      <p>Vo februári 1917, keď nemecké a rakúsko-uhorské sily v prvej svetovej vojne získali prevahu nad&nbsp; Ruskou
        ríšou, nedostatok potravín a zhoršujúce sa pracovné podmienky viedli ruských priemyselných robotníkov k štrajku.
        Keď sa nepokoje rozšírili po celej krajine, cár Nikolaj II. bol nútený odstúpiť a bola ustanovená dočasná ruská
        vláda, ktorá mala riadiť krajinu až do uskutočnenia demokratických volieb.</p>
      <p>Avšak 7. novembra 1917 – alebo 25. októbra podľa tradičného juliánskeho kalendára – spustila skupina
        komunistických revolucionárov na čele s Vladimírom Leninom ozbrojené povstanie v ruskom hlavnom meste Petrograde
        (dnešný Petrohrad). V tzv. Októbrovej revolúcii Leninova boľševická strana zvrhla dočasnú vládu a ustanovila
        prvý komunistický režim na svete.</p>
      <p>O necelé tri týždne neskôr, počas demokratických volieb do Ústavodarného zhromaždenia, získala strana
        socialistických revolucionárov väčšinu hlasov a väčšinu kresiel. Boľševici získali menej ako 25 percent hlasov a
        iba hŕstku zástupcov v zhromaždení.</p>
      <p>Po tejto prehre Lenin nedodržal svoj skorší sľub, že bude rešpektovať výsledky volieb.</p>
      <p>Keď sa 18. januára 1918 konalo&nbsp; zasadanie&nbsp; Ústavodarného zhromaždenia, Lenin prehlásil zhromaždenie
        za nepriateľa ľudu. Potom, čo sa boľševici vopred pripravili na zavedenie stanného práva a zmocnili sa vládnej
        správy od dočasnej vlády, zmobilizovali vojenské jednotky, aby násilne rozpustili zhromaždenie, čím demokratický
        proces v Rusku zničili.</p>
      <p>Rovnako ako samotné ruské marxistické hnutie, Leninov vzostup nebol celkom len ruskou záležitosťou. Napriek
        ukončeniu cárskej vlády Rusko pokračovalo vo vojne na strane Francúzska a Veľkej Británie proti nemeckým
        centrálnym mocnostiam. Pruský kráľ Viliam II., ktorý počítal s tým, že boľševici môžu uviesť Rusko do
        politického chaosu – čím odstránia hlavnú hrozbu pre&nbsp; Nemecko hroziacu z východného frontu – umožnil
        Leninovi bezpečný&nbsp; návrat do Ruska cez Nemecko a Švédsko do Fínska, ktoré bolo v tom čase územím Ruskej
        ríše. Viliam II. tiež poskytol Leninovi peniaze, zbrane a strelivo. Do konca prvej svetovej vojny dostali
        boľševici od Nemecka najmenej 50 miliónov mariek.<a href="#_edn3" id="_ednref3">[3]</a></p>
      <p>Winston Churchill povedal o nemeckej úlohe pri Leninovom návrate toto: „Na Rusko použili tú najsmrtonosnejšiu
        zbraň. Previezli Lenina zo Švajčiarska do Ruska v pevne uzavretom nákladnom aute, akoby prepravovali nejaký typ
        morového vírusu.<a href="#_edn4" id="_ednref4">[4]</a></p>
      <p>Októbrová revolúcia a následný leninský puč boli počiatkom všetkých násilných komunistických hnutí na celom
        svete v 20. storočí. Tie vyvolali medzinárodný vzostup komunizmu a nespočetné množstvo katastrof, ktoré
        nasledovali.</p>
      <p>Keď sa zmocnili moci Ústavodarného zhromaždenia, boľševici sa pustili do ruských robotníkov, ktorí boli
        začiatkom roku 1917 prví, kto sa vzoprel komunistickej diktatúre. Desiatky tisíc robotníkov z Petrohradu
        a&nbsp;z Moskvy vyšli do ulíc, kde usporiadali sprievody a demonštrácie proti rozpusteniu Ústavodarného
        zhromaždenia. Boľševickí vojaci proti prejavom nespokojnosti zakročili so smrtonosnou silou. Ulice Petrohradu a
        Moskvy boli plné krvi.</p>
      <p>Zväz ruských železničiarov, ktorý bol najväčším odborovým zväzom v krajine, oznámil štrajk na protest proti
        boľševickému prevratu a získal širokú podporu mnohých ďalších odborových organizácií. Rovnako ako v prípade
        robotníkov z Petrohradu a&nbsp;Moskvy, Komunistická strana Sovietskeho zväzu (KSSZ) štrajkujúcich násilne
        potlačila za pomoci ozbrojených síl a Zväz železničiarov a ďalšie nezávislé združenia zakázala.</p>
      <p>V&nbsp;marci 1918 sa boľševici premenovali na Celoruskú komunistickú stranu. (V roku 1925, potom ako
        v&nbsp;roku 1922 založili Zväz sovietskych socialistických republík, sa strana opäť premenovala na Celozväzovú
        komunistickú stranu. V&nbsp;roku 1952 sa nakoniec formálne stala Komunistickou stranou Sovietskeho zväzu.) Tie
        robotnícke organizácie, ktoré zostávali, boli postupne prinútené podrobiť sa vláde komunistickej strany.</p>
      <p>V lete v roku 1918 Rusko čelilo masívnemu nedostatku potravín kvôli prebiehajúcej občianskej vojne medzi
        rôznymi komunistickými frakciami (vrátane boľševikov), oblastnými hnutiami za nezávislosť a&nbsp;Bielym hnutím,
        ktoré viedli antikomunistickí ruskí vojenskí predstavitelia. V júni sa krajina nachádzala na pokraji hladomoru a
        Lenin vyslal Josifa Stalina do Caricynu (dnešný Volgograd), aby získal obilie z oblasti povodia Volgy, ktorá je
        tradične známa ako obilnica ruského poľnohospodárstva.</p>
      <p>Tyrania komunistickej strany vyvolala odpor roľníkov. V auguste 1918 sa roľníci v oblasti Penza vzbúrili v
        ozbrojenom povstaní a vzbura sa rýchlo rozšírila do okolitých oblastí. Strana vyslala jednotky, ktoré mali
        povstanie potlačiť, a Lenin poslal telegram miestnym boľševikom v oblasti Penza:</p>
      <p><em>1. Obeste (a zaistite, aby obesení boli ľuďom na očiach) nie menej ako 100 známych statkárov, bohatých ľudí
        a pijavíc.</em></p>
      <p><em>2. Zverejnite ich mená.</em></p>
      <p><em>3. Zabavte všetko ich obilie.</em></p>
      <p><em>4. Určite rukojemníkov podľa pokynov vo včerajšom telegrame.</em></p>
      <p><em>Urobte to tak, aby to ľudia v okolí stoviek kilometrov mohli vidieť, triasť sa, vedieť a kričať ...<a
        href="#_edn5" id="_ednref5">[5]</a> </em></p>
      <p>Na jar roku 1919 hladujúci robotníci v mestách po celom Rusku niekoľkokrát vstúpili do štrajku a žiadali, aby
        dostávali rovnaké prídely potravín ako vojaci Červenej armády. Žiadali tiež zrušenie politických výsad, ktorých
        sa dostávalo komunistom, a požadovali základnú slobodu prejavu a demokratické voľby. So všetkými týmito hnutiami
        sa vysporiadala tajná polícia „Čeka“ (predchodca KGB), ktorá štrajkujúcich uväznila alebo zastrelila.</p>
      <p>Jednou z najbohatších provincií v Rusku pred októbrovou revolúciou bol Tambov, ležiaci juhovýchodne od Moskvy.
        Keď Sovietsky zväz&nbsp; vyslal do Tambova „skupiny na zabavenie obilia“, ktoré mali zabaviť zásoby obilia, viac
        ako 50 000 tambovských farmárov vytvorilo miestnu domobranu, aby bojovali proti zabavovacím skupinám
        komunistickej strany v tzv. Tambovskom povstaní. V júni 1921 sovietsky režim poveril vojenského veliteľa
        Michajla Tuchačevského, aby proti tzv. lupičom zakročil jedovatým plynom.<a href="#_edn6"
                                                                                    id="_ednref6">[6]</a> Tuchačevského
        použitie chemických zbraní v kombinácii s požiarmi, ktoré horeli v celom regióne, spôsobilo, že veľká časť
        Tambova úplne spustla.&nbsp; Odhaduje sa, že stotisíc tambovských roľníkov, ktorí sa povstania zúčastnili, spolu
        s&nbsp;príbuznými, bolo uväznených alebo vyhostených. Pri povstaní zahynulo asi 15 000 ľudí.<a href="#_edn7"
                                                                                                       id="_ednref7">[7]</a>&nbsp; V
        30. rokoch 20. storočia bol sám Tuchačevskij mučený a popravený počas Stalinových čistiek v Červenej armáde.</p>
      <p>Založenie totalitnej diktatúry sovietskeho režimu, úplná zrada ruských robotníkov a masové vraždenie miliónov
        bežných občanov neskôr učebnicovo odkopírovala ČKS. Odkedy sa chopila moci v 50. rokoch 20. storočia, priniesla
        katastrofy v čínskej histórii dovtedy nevídané.</p>
      <h3>b) Čínska komunistická strana sa zhosťuje moci</h3>
      <p>Marxizmus a iné ľavicové ideológie sa do Číny dostali zo zahraničia pred pádom dynastie Čching v roku 1911 a
        všeobecne sa rozšírili vďaka radikálnym učencom a mládeži, ktorí zúfalo hľadali riešenia hrozieb, ktorým krajina
        čelila.</p>
      <p>V 20-tych rokoch 20. storočia viedli čínski komunistickí aktivisti Hnutie za novú kultúru, pričom kritizovali
        tradičnú kultúru, ktorú obviňovali zo zaostalosti Číny. V roku 1919 čínski komunisti, s finančnou podporou
        sovietskeho režimu, zohrali vedúcu úlohu v Hnutí 4. mája, rade študentských protestov, ktoré prerástli do hnutia
        za novú kultúru a ktoré protestovali proti&nbsp; zahraničným mocnostiam ako aj čínskym politickým elitám.</p>
      <p>V roku 1920 boľševici vyslali do Číny Grigorija Vojtinského, aby tu založil miestnu komunistickú organizáciu. V
        júli 1921 bola v Šanghaji Chen Duxiuom, Li Dazhaom a ďalšími čínskymi marxistami založená ČKS.</p>
      <p>Novovytvorená ČKS fungovala pomocou trikov. V roku 1923 Lenin vyslal Michaila Borodina, aby dohovoril
        spojenectvo medzi Čínskou národnou stranou (Kuomintang – KMT) a Sovietskym zväzom. Podľa podmienok spojenectva
        KMT prevzal pod seba rodiacu sa ČKS ako svoju pobočku, čo poskytlo komunistom ďalšie príležitosti na oslabenie
        nacionalistických snáh KMT.</p>
      <p>Vedomý si toho, že ČKS sa chcela spojiť s KMT kvôli získaniu moci, nacionalistický vodca Čang Kaj-šek spustil v
        roku 1927 čistky voči komunistom. Počas niekoľkých nasledujúcich rokov podnikla KMT niekoľko vojenských kampaní
        zameraných na zničenie „sovietskych“ oblastí v južnej Číne, ktoré boli pod správou ČKS. Tieto operácie boli
        čiastočne úspešné, no komunistom sa podarilo utiecť do oblasti Yan'anu v severozápadnej Číne, kde si založili
        základňu. V tridsiatych rokoch 20. storočia rastúca hrozba zo strany cisárskeho Japonska prinútila KMT
        pozastaviť svoje operácie proti rebélii ČKS.</p>
      <p>ČKS v plnej miere využila nestabilitu Číny v súvislosti s japonským expanzionizmom. Keďže do vojny sa zapojili
        hlavne vojská KMT, ČKS to využila na rozšírenie svojich síl. Keď Japonci napadli Čínu v roku 1937, Červená
        armáda ČKS bola takmer porazená KMT. Keď Čína vojnu v roku 1945 vyhrala, komunisti sa chválili 1,32 miliónmi
        stálych vojenských oddielov a 2,6 miliónmi členov domobrany (milicionárov).<a href="#_edn8"
                                                                                      id="_ednref8">[8]</a>&nbsp; Po
        kapitulácii Japoncov využila ČKS mierové rokovania s KMT za zakrytie toho, že tajne rozmiestňuje svoje vojenské
        sily pre pripravovanú občiansku vojnu.</p>
      <p>Milióny ľudí položili život na bojiskách druhej svetovej vojny, avšak neočakávaným dôsledkom vojny bola
        závratne rýchla expanzia totalitného komunizmu. Diplomatické úsilie ČKS voči Spojeným štátom a Sovietskemu zväzu
        viedli k opusteniu ich politiky podporujúcej nacionalistov. V roku 1949 ČKS porazila KMT a&nbsp;zaviedla to, čo
        sa stalo najzlovoľnejším totalitným komunistickým režimom na svete, Čínsku ľudovú republiku (ČĽR).</p>
      <p>Na svojom vrchole vládlo svetové komunistické hnutie jednej tretine ľudstva. Jeho vplyv pokrýval Rusko a Čínu,
        najväčšie národy sveta z hľadiska veľkosti a populácie. Komunistické vlády sa rozprestierali po rozsiahlych
        územiach Európy a Ázie a mnoho krajín v Afrike, Južnej Amerike a juhovýchodnej Ázii sa stalo klientmi alebo
        spojencami Sovietskeho zväzu alebo ČĽR.</p>
      <p>Čína má širokú a rozsiahlu kultúru a históriu trvajúcu 5000 rokov. Jej obyvatelia uchovávajú tradície
        uctievania bohov a uctievania nebies. Duch komunizmu nemohol zničiť tradičnú čínsku kultúru len nejakým
        jednoduchým sprisahaním.</p>
      <p>Potom, čo v Číne získala moc a založila ČĽR, ČKS sa zamerala na spoločenské elity, ktoré sú nositeľmi tradičnej
        kultúry. Ničila hmotné artefakty čínskej civilizácie a prerušila spojenie medzi Číňanmi a ich bohmi. Tradičné
        čínske dedičstvo bolo prostredníctvom masového zabíjania nahradené „kultúrou strany“. S každou ďalšou generáciou
        sa kultúra strany ešte viac vryla do myslí Číňanov, do ich pohľadu na svet.</p>
      <p>Len čo prevzala moc, ČKS si začala vymýšľať nepriateľov, začínajúc vraždením elít. Na vidieku povraždila
        vlastníkov pôdy a šľachtu. V mestách zabíjala obchodníkov a vytvárala atmosféru teroru, zatiaľ čo drancovala
        bohatstvo občianskej spoločnosti.</p>
      <p>Aby roľníkov vyburcovala k zabíjaniu vlastníkov pôdy a „bohatých sedliakov“ a na podporu&nbsp; nového
        komunistického režimu, zaviedla ČKS takzvanú pozemkovú reformu, ktorá prisľúbila roľníkom ich vlastnú pôdu. Ale
        potom, čo roľníci vyvraždili vlastníkov pôdy, ČKS vyhlásila, že pôda sa má roľníkom vrátiť vo forme družstiev. V
        praxi to znamenalo, že pôda roľníkom nakoniec aj tak nepatrila.</p>
      <p>V marci 1950 ČKS vydala „nariadenie o prísnom potlačení kontrarevolučných elementov“, tiež známe ako kampaň na
        potlačenie kontrarevolucionárov, ktorá sa&nbsp; zameriavala&nbsp; na zabíjanie vlastníkov pôdy a bohatých
        roľníkov na vidieku. ČKS vyhlásila, že koncom roka 1952 bolo odstránených viac ako 2,4 milióna
        „kontrarevolucionárov“. V skutočnosti bolo zavraždených viac ako 5 miliónov ľudí.<a href="#_edn9"
                                                                                            id="_ednref9">[9]</a></p>
      <p>Potom, čo pozabíjala vlastníkov pôdy a bohatých roľníkov na vidieku, spustila ČKS kampaň proti trom zlám a
        kampaň proti piatim zlám, aby vyvraždila bohatých mešťanov. Pod takýmto tlakom sa mnoho kapitalistov rozhodlo
        spáchať samovraždu aj so všetkými svojimi rodinnými príslušníkmi.</p>
      <p>ČKS sa však nezastavila pri vyhladzovaní vlastníkov pôdy a kapitalistov. Kradla majetok roľníkom,
        maloobchodníkom a remeselníkom. Po tomto masovom vraždení zostala prevažná väčšina robotníckej triedy i naďalej
        nesmierne chudobná.</p>
      <h2>2. Brutalita komunistickej nadvlády</h2>
      <p>Hoci sa komunistické režimy dostávajú k moci pomocou klamstva a násilia, ich najhoršie zločiny sú páchané v
        čase mieru. Ako v Sovietskom zväze tak aj v ČĽR po revolúcii ihneď nasledovali krvavé politické kampane na
        odstránenie „triednych nepriateľov“, hladomory, zriadenie koncentračných táborov a tvrdé čistky kádrov strany,
        ako aj šírenie teroru medzi obyvateľstvom. Podobná brutalita bola všadeprítomná v celom komunistickom bloku a
        všetky komunistické štáty, ktoré zostali, majú represívny autoritársky režim.</p>
      <h3>a) Zverstvá sovietskych komunistov</h3>
      <p>Po ukončení veľkých vojenských kampaní v roku 1922 sa boľševici de facto stali víťazmi ruskej občianskej vojny.
        Sovietska komunistická strana však čelila bezprostrednej kríze, ktorú si privodila vlastným konaním. Nadšene
        implementované marxistickej politiky viedlo k rozsiahlemu hladomoru, ktorý v Rusku zabil milióny ľudí.
        Komunistické vedenie bolo nútené pozastaviť väčšinu svojho politického programu – označovaného ako „vojnový
        komunizmus“ – a zaviesť novú hospodársku politiku. Prinieslo to prímerie s ruskými roľníkmi, keďže im bolo
        umožnené obhospodarovať vlastnú pôdu a predávať úrodu bez zásahu štátu.</p>
      <p>Sovietski komunisti však novou hospodárskou politikou zamýšľali len to, aby poslúžila ako núdzové opatrenie na
        zabránenie hroziacej vzbury. Počas hladomoru spôsobeného politikou vojnového komunizmu Leninov priateľ
        poznamenal, že katastrofa, ktorú Lenin zorganizoval, bola dobrá v tom, že „zničila vieru nielen v cára, ale aj v
        Boha“.<a href="#_edn10" id="_ednref10">[10]</a></p>
      <p>Komunistické režimy používajú teror a masové vraždenie ako prostriedok na posilnenie svojej diktatúry. V roku
        1928 bol program novej hospodárskej politiky zrušený a nahradený programom kolektívnych družstiev,
        kontrolovaných režimom. Ruskí roľníci, ktorí nesúhlasili so zabavením pôdy a obilia, sa pevne postavili na odpor
        komunistickej strane. Za svoju neposlušnosť draho zaplatili.</p>
      <h4>Hladomor použitý na zabíjanie ľudí</h4>
      <p>Väčšina obetí komunizmu bola zabitá umelo vytvorenými hladomormi. V rokoch 1932 až 1933 masové hladovanie
        spôsobené Sovietskou komunistickou stranou zabilo milióny ľudí, väčšinou roľníkov, v oblastiach Ukrajiny,
        južného Ruska a Strednej Ázie. Ukrajinský hladomor, známy ako Holodomor, si vyžiadal životy približne štyroch
        miliónov ľudí.</p>
      <p>Po skončení občianskej vojny v roku 1922 zaviedla komunistická strana politiku kolektivizácie, ktorá sa stretla
        so širokým odporom ukrajinského roľníctva. Sovietsky režim sa s tým vysporiadal tak, že označil väčšinu
        schopných poľnohospodárov hanlivým termínom&nbsp; „kulak“ a vyhnal ich do oblastí západnej Sibíri a do republík
        strednej Ázie. Odstránenie týchto poľnohospodárov znamenalo pre ukrajinské poľnohospodárstvo obrovskú stratu, v
        dôsledku ktorej v roku 1932 produkcia prudko klesla.</p>
      <p>V zime na prelome rokov 1932 – 1933 sovietska vláda prerušila dodávky potravín na Ukrajinu a pozdĺž jej hraníc
        vybudovala bezpečnostné ploty. Sprvu Ukrajinci prežívali vďaka zelenine a zemiakom uskladneným vo svojich
        domácnostiach, ale aj tie stranícke úrady rýchlo zabavili. Mnoho farmárov zomrelo hladom. Úrady zabránili
        dedinčanom cestovať do miest za potravou. Mnoho ľudí zomrelo hladom, keď putovali pozdĺž železničných tratí. V
        zúfalstve ľudia jedli zdochliny mačiek, psov a hospodárskych zvierat. Niektorí sa dokonca uchýlili ku
        kanibalizmu.<a href="#_edn11" id="_ednref11">[11]</a></p>
      <p>Holodomor urobil z viac ako milióna ukrajinských detí siroty. Mnohé z nich sa stali bezdomovcami a nemali inú
        možnosť, ako žobrať o jedlo v mestách. Aby sa Stalin tejto „nepríjemnosti“ zbavil, podpísal nariadenie
        dovoľujúce polícii strieľať deti už od 12 rokov veku. Počas hladomoru boli ulice v tom čase ukrajinského
        hlavného mesta Charkova plné tiel obetí hladu.</p>
      <h4>Gulagy: Prvé&nbsp; koncentračné tábory v Európe</h4>
      <p>5. septembra 1918 nariadil Lenin založenie prvého sovietskeho koncentračného tábora na Soloveckých ostrovoch,
        ktorý mal slúžiť na väznenie politických väzňov a disidentov,&nbsp; ktorí boli proti októbrovej revolúcii.
        Komunistická strana v nasledujúcich rokoch vybudovala sieť koncentračných táborov po celom Sovietskom zväze –
        notoricky známych ako „gulagy“ Stalinovej éry. (Termín „gulag“ je skratka v ruštine a znamená „Hlavná správa
        táborov.“)</p>
      <p>Systém gulagov sa pod vedením Stalina rozrástol do monštruóznych rozmerov, keď komunistická strana
        zintenzívňovala politický teror a uskutočňovala stále väčšie čistky. V čase Stalinovej smrti v roku 1953
        existovalo 170 administratívnych správ gulagu, do ktorých patrilo viac ako 30 000 samostatných táborov
        rozptýlených po celom Sovietskom zväze. Alexander Solženicyn nazval tento systém „Súostrovie Gulag“ a pomenoval
        tak aj svoju knihu. Solženicyn vymenoval tridsaťjeden rôznych metód, ktoré používala sovietska tajná polícia k
        psychickému a fyzickému podlomeniu väzňov, ktorí sa potom priznávali k akémukoľvek zločinu.<a href="#_edn12"
                                                                                                      id="_ednref12">[12]</a>
      </p>
      <p>Tí, ktorí boli poslaní do gulagov, nepretržite trpeli nedostatkom jedla a oblečenia, pričom boli v mrazivej
        ruskej zime nútení vykonávať ťažkú ​​prácu 12 až 16 hodín denne. Počet obetí bol obrovský. Mnoho ľudí bolo
        uväznených s celou svojou rodinou, manželov uväznili, ženy poslali do vyhnanstva. Dokonca ani starší ľudia,
        ktorí mali nad 80 rokov, neboli ušetrení. Do gulagov boli posielaní ľudia zo všetkých spoločenských vrstiev, od
        vysokopostavených straníckych elít, štátnych vodcov a vojenských veliteľov, až po úplne obyčajných ľudí
        rozličných profesií, vrátane nábožensky veriacich, inžinierov, technikov, lekárov, študentov, profesorov,
        továrenských robotníkov a roľníkov.</p>
      <p>Medzi rokmi 1930 až 1940, v období Stalinovho predvojnového teroru, zahynulo podľa konzervatívnych odhadov v
        systéme gulagov viac ako 500 000 väzňov. Systém gulagov bol formálne zrušený v roku 1960. Zatiaľ čo skutočné
        čísla zostávajú neznáme, predpokladá sa, že v gulagoch bolo uväznených 18 miliónov ľudí a viac ako 1,5 milióna
        ľudí zomrelo.</p>
      <p>Koncentračné tábory sa zvyčajne považujú za výtvor nacistov, ale podobným represiám po celom svete, v
        komunistických aj nekomunistických režimoch, predchádzal systém sovietskych gulagov. Podľa bývalého sovietskeho
        vojenského spravodajského dôstojníka a populárneho historika Viktora Suvorova vyslal pred druhou svetovou vojnou
        Adolf Hitler do Ruska dôstojníkov Gestapa, aby sa tu učili zo skúseností sovietov pri budovaní gulagov.</p>
      <h4>Veľký teror sa obracia proti sovietskej elite</h4>
      <p>Tí, čo nasledujú ducha komunizmu sa tiež isto stanú jeho obeťami. Stalo sa tak počas Stalinovej éry, keď
        komunistická strana uskutočňovala krvavé čistky vo svojich vlastných radoch. Po Leninovej smrti sa Stalin
        zameral na horné vrstvy komunistického vedenia.</p>
      <p>Represie dosiahli vrchol medzi rokmi 1936 a 1938, keď milióny členov strany a sovietskych úradníkov čelilo
        zmanipulovaným súdnym procesom za smiešne obvinenia v udalosti známej ako Veľký teror. Stovky tisíc postrieľali,
        často po priznaniach vynútených mučením.</p>
      <p>Z 1 966 delegátov na 17. zjazde komunistickej strany sovietskeho zväzu v roku 1934 bola viac ako polovica (1
        108) zatknutých a obvinených z kontrarevolučnej činnosti. Zo 139 členov a kandidátov na členov ústredného
        výboru, ktorí boli na 17. zjazde zvolení, 110 zabili.<a href="#_edn13" id="_ednref13">[13]</a> Lavrentij Berija,
        šéf Stalinovej tajnej polície, raz prehlásil: „Ukáž mi muža a ja ti nájdem zločin.“ Všetci členovia politbyra s
        výnimkou Stalina, ktorí prežili Leninovu smrť v roku 1924 – Lev Kamenev, Grigorij Zinoviev, Alexej Rykov,
        Michajl Tomskij a Lev Trockij – boli do roku 1940 popravení alebo zavraždení.</p>
      <p>Žiadna časť spoločnosti nebola počas Veľkého teroru a stalinských čistiek ušetrená. Represie v náboženských,
        vedeckých, vzdelávacích, akademických a umeleckých sférach predchádzali čistkám, ktoré zničili vojenskú a
        politickú elitu. Hlavnými obeťami Stalinovho teroru však boli obyčajní ľudia, nielen príbuzní a známi
        obvinených, ale aj robotníci a ďalší radoví sovietski občania obvinení a potrestaní za vymyslené zločiny.</p>
      <p>Ani tí, čo popravovali, neunikli teroru. Genrich Jagoda, šéf tajnej polície do roku 1936, bol v roku 1937
        zatknutý a v ďalšom roku zastrelený. Jeho nástupca Nikolaj Ježov stratil moc v roku 1939, potom, čo sám
        dohliadal na najkrvavejšiu sériu vnútorných čistiek. Bol zastrelený v&nbsp;komore pre popravy, ktorú sám
        navrhol.</p>
      <p>Dokonca ani dnes neexistujú žiadne odpovede týkajúce sa počtu zadržaných, zabitých, uväznených alebo poslaných
        do exilu počas teroru Stalinovej éry. V predvečer rozpadu Sovietskeho zväzu v júni 1991 šéf KGB Vladimir
        Krjučkov uviedol, že medzi rokmi 1920 a 1953 bolo „potlačených“ asi 4,2 milióna ľudí, z toho len počas Veľkého
        teroru dva milióny.<a href="#_edn14" id="_ednref14">[14]</a> Alexander Jakovlev, sovietsky reformný politik za
        sovietskej a Jeľcinovej éry, v rozhovore v roku 2000 povedal, že obetí stalinských represií nebolo menej ako 20
        miliónov.<a href="#_edn15" id="_ednref15">[15]</a></p>
      <h3>b) Vražedné kampane ČKS</h3>
      <p>Traumatické politické vyšetrovania neraz končiace smrťou sú jedným z rysov, ktoré čínske komunistické hnutie
        malo ešte predtým, ako sa v roku 1949 zmocnilo pevninskej Číny. V roku 1942, keď sa ČKS ukryla na severozápade
        Číny, Mao Ce-Tung zahájil Yananské nápravné hnutie. Stranícke kádre boli vystavené trýznivému zaobchádzaniu
        vrátane mučenia, zadržiavania a&nbsp; „reformy mysle“, čo údajne malo odstrániť tých s nedostatočnou
        ideologickou lojalitou. Počas hnutia, ktoré bolo prvou masovou politickou kampaňou ČKS, boli zabité tisíce
        ľudí.</p>
      <p>Od roku 1949, keď bol zavedený režim ČĽR, až&nbsp; do roku 1966 prišli o život desiatky miliónov Číňanov v
        kampani na potlačenie kontrarevolucionárov, v kampaniach proti trom a piatim zlám, v kampani proti pravičiarom a
        vo veľkom hladomore spôsobenom „Veľkým skokom vpred“.</p>
      <p>Po tomto období hromadného zabíjania nasledovali krvavé boje v radoch ČKS. Ako postupne dospela nová generácia
        Číňanov, vychovávaných ako ateistické „vlčie mláďatá“, indoktrinovaná vzdelávacím systémom straníckej kultúry
        komunizmu, začal duch komunizmu kampaň ešte zbesilejšieho zabíjania a ničenia, aby zničil 5 000 rokov starú
        tradičnú čínsku kultúru.</p>
      <p>Kultúrna revolúcia bola poslednou a v niektorých ohľadoch najničivejšou politickou kampaňou Maovej éry. Začala
        v roku 1966 a tiahla sa až do posledného desaťročia Maovho života, pričom jej cieľom bolo násilné nahradenie
        tradičnej čínskej kultúry straníckou kultúrou.</p>
      <h4>Veľký čínsky hladomor</h4>
      <p>Medzi rokmi 1959 až 1962 prežila Čína najhrozivejší hladomor sveta. Aby oklamala svet, ČKS stále tvrdí, že išlo
        o tri roky „prírodných katastrof“.</p>
      <p>V skutočnosti však v roku 1958 ČKS unáhlene spustila hnutie ľudových komún a kampaň Veľkého skoku vpred. Tieto
        divoké vládne projekty nielenže vyčerpali zásoby obilia a zničili čínsku poľnohospodársku produkciu, ale boli
        tiež podporované záplavou falošných správ na všetkých úrovniach vedenia krajiny, od vidieckych oblastí až po
        mestá. ČKS použila tieto správy ako ospravedlnenie pre zhromažďovanie obilia od roľníkov, ktorí boli prinútení
        odovzdať režimu svoje potraviny, semená a krmivo pre zvieratá.</p>
      <p>Administratívne orgány ČKS na všetkých úrovniach vyslali na vidiek skupiny. Tie, aby nešťastným roľníkom
        zabavili posledné zvyšky jedla, použili&nbsp; mučenie a výsluchy. Po vzore sovietskych komunistov ČKS zabránila
        dedinčanom vstupovať do miest hľadať jedlo, čo spôsobilo masové úmrtia celých rodín a dokonca celých dedín.
        Mŕtve telá obetí hladomoru sa všade na vidieku povaľovali na zemi. Keď chytili roľníkov, ktorí kradli, aby
        prežili, zastrelili ich.</p>
      <p>Obilie zhabané vládou bolo vymenené za veľké množstvo sovietskych zbraní alebo za zlato, ktoré ČKS využívala na
        splatenie dlhov – to, že čínski ľudia prichádzali o život, strane nevadilo. Iba za tri roky zomreli kvôli
        veľkému čínskemu hladomoru desiatky miliónov ľudí.</p>
      <h4>Kultúrna revolúcia: zabíjanie a kultúrna genocída</h4>
      <p>Kultúrna revolúcia zopakovala besnenie Yananského nápravného hnutia na celonárodnej úrovni. Fanatická mládež
        bola povzbudzovaná k tomu, aby rozbíjala, bila, mučila a vraždila kvôli zničeniu tzv. „štyroch prežitkov“ –
        starých obyčajov, starej kultúry, starých zvykov a starých myšlienok – Číny.</p>
      <p>16. mája 1966 vydala ČKS tzv. „Obežník 16. mája“, ktorým začala kultúrnu revolúciu. V auguste toho istého roku
        vytvorili študenti stredných škôl v Pekingu tzv. Červené gardy, ktoré viedli deti vysoko postavených kádrov
        komunistickej strany. Táto skupina mladých chuligánov divoko rabovala, útočila a zabíjala po celom Pekingu. Na
        konci mesiaca, známeho ako Červený august, boli v Pekingu zavraždené tisíce ľudí.</p>
      <p>V pekinskej štvrti Ta-sing bolo medzi 27. augustom a 1. septembrom, za šesť dní, zabitých 325 ľudí v 48
        výrobných brigádach 13 ľudových komún. Obeťami boli ľudia najrôznejšieho veku, z ktorých niektorí mali vyše 80
        rokov, a najmladší iba 38 dní. 22 rodín bolo kompletne vyhladených. Členovia Červených gárd svoje obete ubili,
        dobodali alebo uškrtili. Dojčatá a batoľatá zabíjali tak, že dieťaťu pristúpili jednu nohu a potom ho roztrhli
        na dve časti.<a href="#_edn16" id="_ednref16">[16]</a></p>
      <p>Tým, že duch komunizmu viedol ľudí k bitiu a zabíjaniu, vymazal v nich postupne ľudský súcit a vymyl im mozog
        heslom „jednajte s nepriateľom so strnulou krutosťou mrazivej zimy“. S každým ďalším zločinom proti ľudskosti
        ČKS postupne odstraňovala tradičnú kultúru a morálne cnosti Číňanov. Z mnohých ľudí otrávených kultúrou strany
        sa stali nástroje vraždenia.</p>
      <p>Keď&nbsp; ľudia vidia&nbsp; krvavé skutky komunistického totalitného štátu, nedokážu pochopiť, ako by sa niekto
        mohol znížiť k tak neľudskému barbarstvu.</p>
      <p>Odhadnúť počet obetí kultúrnej revolúcie je náročná úloha. Väčšina štúdií odhaduje minimálny počet obetí na dva
        milióny. Americký profesor R. J. Rummel, ktorý sa zaoberal výskumom hromadného zabíjania, vo svojej knihe <em>Krvavé
          čínske storočie: Genocída a masové vraždenie od roku 1900</em> (China 's Bloody Century<em>: Genocide and Mass
          Murder Since 1900</em>) uviedol, že kultúrna revolúcia si vyžiadala 7,73 milióna ľudských životov.<a
          href="#_edn17" id="_ednref17">[17]</a></p>
      <p>Tung Pao-Sun, docent na čínskej univerzite v Šan-tungu, a Ting Lung-ťia, zástupca riaditeľa ústavu pre výskum
        histórie strany v Šan-tungu, v roku 1997 spoločne napísali knihu s názvom <em>Očistiť nevinných – Rehabilitovať
          nespravodlivo obvinených a odsúdených</em>. V knihe citujú Jie Ťien-jinga, vtedajšieho podpredsedu Ústredného
        výboru ČKS, ktorý na slávnostnom zakončení pracovnej konferencie ústredného výboru 13. decembra 1978 vyhlásil:
        „Po svojom dva roky a sedem mesiacov dlhom komplexnom vyšetrovaní dospel ústredný výbor k záveru, že počas
        kultúrnej revolúcie zomrelo 20 miliónov ľudí, viac ako 100 miliónov ľudí bolo politicky prenasledovaných, ... a
        premrhalo sa viac ako 800 miliárd jüanov.“<a href="#_edn18" id="_ednref18">[18]</a></p>
      <p>V auguste 1980 vodca ČKS Teng Siao-pching poskytol dva rozhovory talianskej novinárke Oriane Fallaciovej, v
        ktorých uviedol, že je ťažké kvantifikovať zničujúci dopad kultúrnej revolúcie:</p>
      <p>„Ľudia boli rozdelení do dvoch frakcií, ktoré sa navzájom masakrovali. ... Je ťažké odhadnúť, pretože zomreli z
        rôznych príčin. Čína je okrem toho taká obrovská krajina. Ale počúvajte: Zomrelo toľko ľudí, že aj keby sa počas
        nej nevyskytli ďalšie tragédie, počet mŕtvych by stačil na to, aby bolo možné povedať, že kultúrna revolúcia
        bola zlá vec.“<a href="#_edn19" id="_ednref19">[19]</a></p>
      <p>Teng opísal typický prípad: Kchang Šeng, šéf tajnej polície ČKS, obvinil&nbsp; Čao Ťien-mina, straníckeho
        tajomníka z provincie Jün-nan, zo zrady a z toho, že je agentom Kuomintangu. Nielenže bol Čao uväznený, ale jeho
        pád tiež ovplyvnil 1,38 milióna ľudí v celej provincii, z ktorých 170 000 bolo prenasledovaných na smrť a 60 000
        bolo zbitých tak, že im ostali trvalé následky na zdraví.<a href="#_edn20" id="_ednref20">[20]</a></p>
      <h4>Bezprecedentné zlo: Prenasledovanie Falun Gongu</h4>
      <p>Desaťročia vražedného násilia a ateistickej indoktrinácie ČKS si vybralo masívnu daň na morálnom stave
        spoločnosti a spôsobilo, že ľudia sa nachádzajú hlboko pod štandardom, ktorý Bohovia od ľudstva vyžadujú.
        Dokonca aj mnohí z tých, ktorí stále veria v Boha, nemajú povedomie o tom, čo je to pravá viera, pretože sú
        uväznení vo falošných náboženských organizáciách, kontrolovaných ČKS. Ak by úpadok aj naďalej pokračoval,
        ľudstvo by čelilo istému zániku, ako sa to prorokuje vo svätých textoch všetkých starovekých civilizácií.</p>
      <p>Ale duch komunizmu je odhodlaný zabrániť človeku, aby mohol byť Stvoriteľom spasený. Z tohto dôvodu duch
        komunizmu zničil tradičnú kultúru a poškodil ľudské morálne hodnoty.</p>
      <p>Aby ľuďom navrátil späť ľudskú morálku a poskytol im cestu k spáse, začal pán Li Chung-č’ na jar v roku 1992
        verejne vyučovať Falun Gong – duchovnú prax založenú na viere v princípy pravdivosti, súcitu a
        znášanlivosti.</p>
      <p>Falun Gong, tiež nazývaný Falun Dafa, sa počas niekoľkých krátkych rokov rozšíril po celej Číne. Keď
        praktizujúci spoločne so svojimi príbuznými a priateľmi zažívali zázraky týkajúce sa zlepšeného zdravia a svojho
        charakteru, postupne sa prax začali učiť v Číne i po celom svete desiatky miliónov ľudí. Pretože tak veľa ľudí
        praktizovalo Falun Gong a držalo sa vyšších štandardov, spoločnosť začala znovu objavovať svoje morálne
        základy.</p>
      <p>Od momentu, keď sa prvýkrát chopila moci, ČKS nikdy nepoľavila v prenasledovaní duchovnej viery. Prirodzene
        teda vníma Falun Gong ako svojho najväčšieho protivníka.</p>
      <p>V júli 1999 vtedajší vodca ČKS Ťiang Ce-min jednostranne nariadil systematické prenasledovanie Falun Gongu a
        tých, ktorí ho praktizujú. V brutálnej kampani, ktorá sa rozšírila do každého kúta Číny, použila strana všetky
        metódy, ktoré si možno predstaviť, aby naplnila Ťiangovo nariadenie: „Fyzicky ich zlikvidujte, finančne ich
        zruinujte a zničte ich povesť.“</p>
      <p>Hlásne trúby strany vystavili čínsky ľud neustálej propagande plnej nenávisti a ohovárania Falun Gongu.
        Princípy pravdivosti, súcitu a znášanlivosti boli odmietnuté, prednosť dostali klamstvá, podlosť a boj. Duch
        komunizmu ešte viac prehĺbil morálnu degeneráciu spoločnosti. V atmosfére nenávisti a útlaku, ktoré boli znova
        prebudené z nečinnosti, čínsky ľud zatváral oči pred prenasledovaním, ktoré sa okolo neho odohrávalo, zrádzajúc
        tak Budhov a Bohov. Niektorí opustili svoje svedomie a zúčastnili sa kampane proti Falun Gongu, nevedomí si
        skutočnosti, že si tak zvolili svoje vlastné zatratenie.</p>
      <p>Duch komunizmu neobmedzil prenasledovanie iba na Čínu. Umlčal národy slobodného sveta, zatiaľ čo čínsky režim
        zbesilo zatváral, vraždil a mučil praktizujúcich Falun Gongu. Oslepený ekonomickými ziskom, slobodný svet mlčal
        alebo dokonca akceptoval klamstvá strany, čo prenasledovateľom dalo voľnú ruku v dopúšťaní sa najhorších
        zločinov.</p>
      <p>Pri prenasledovaní Falun Gongu&nbsp; prišla ČKS s doposiaľ nevídaným zlom: odoberaním orgánov živým ľuďom. Ako
        najväčšia skupina ľudí uväznených pre svoju vieru v Číne sú praktizujúci Falun Gongu zabíjaní na objednávku,
        zaživa vypitvávaní na operačných stoloch štátnych a vojenských nemocníc a ich orgány sa predávajú za desiatky
        tisíc alebo dokonca stovky tisíc dolárov.</p>
      <p>6. júla 2006 kanadskí právnici David Matas a David Kilgour (bývalý kanadský štátny tajomník pre oblasť Ázie a
        Tichomoria) zverejnili správu nazvanú <em>Krvavá žatva: Zabíjanie praktizujúcich Falun Gongu pre orgány</em>.
        Skúmaním 18 dôkazných bodov vrhli svetlo na obrovské zločiny ČKS a nazvali ich „ohavnou formou zla ... na tejto
        planéte nikdy predtým nevidenou“.<a href="#_edn21" id="_ednref21">[21]</a></p>
      <p>V júni 2016 Matas a Kilgour spolu s investigatívnym novinárom Ethanom Gutmannom spolupracovali s tímom
        medzinárodných vyšetrovateľov na aktualizovanom vydaní správy <em>Krvavá žatva </em>a <em>Jatky: Nové
          informácie</em>. Na viac ako 680 stranách obsahujúcich vyše 2 400 odkazov sa spomínajú nespochybniteľné
        informácie, ukazujúce skutočnosť a rozsah odoberania orgánov čínskym komunistickým režimom.</p>
      <p>Snemovňa reprezentantov USA 13. júna 2016 jednomyseľne schválila rezolúciu č. 343,&nbsp; požadujúcu, aby ČKS
        okamžite ukončila násilné odbery orgánov praktizujúcim Falun Gongu a ďalším väzňom svedomia.<a href="#_edn22"
                                                                                                       id="_ednref22">[22]</a>
      </p>
      <p>V júni 2019, po jednoročnom vyšetrovaní, nezávislý <em>Čínsky tribunál </em>v Londýne, dospel jednohlasne k
        záveru, že väzni svedomia v Číne boli – a stále sú –&nbsp; „vo významnej miere“ zabíjaní pre svoje orgány.<a
          href="#_edn23" id="_ednref23">[23]</a> Tribunálu predsedal sir Geoffrey Nice QC, ktorý v minulosti viedol
        trestné stíhanie bývalého juhoslovanského prezidenta Slobodana Miloševiča za jeho vojnové zločiny v Kosove. Súd
        ďalej dospel k záveru, že nasledovníci Falun Gongu sú jedným z hlavných zdrojov orgánov, ktoré poháňajú
        transplantačný priemysel čínskeho režimu. Tento lukratívny obchod vytrvalo podporoval prenasledovanie Falun
        Gongu a prilákal klientov z Číny a&nbsp;z celého sveta, čím z nich ČKS urobila spoluvinníkov na masovom
        vraždení.</p>
      <h2>3. Storočie zabíjania</h2>
      <p>Úvod <em>Čiernej knihy komunizmu</em> poskytuje hrubé odhady obetí komunistických režimov po celom svete.
        Uvádza overený počet 94 miliónov, vrátane:</p>
      <p>20 miliónov v Sovietskom zväze</p>
      <p>65 miliónov v Číne</p>
      <p>2 milióny v Severnej Kórei</p>
      <p>2 milióny v Kambodži</p>
      <p>1,7 milióna v Afrike</p>
      <p>1,5 milióna v Afganistane</p>
      <p>1 milión vo Vietname</p>
      <p>1 milión vo východnej Európe</p>
      <p>150 000 v Latinskej Amerike (predovšetkým na Kube)</p>
      <p>10 000 mŕtvych v dôsledku pôsobenia „medzinárodného komunistického hnutia a komunistických strán, ktoré neboli
        pri moci“.<a href="#_edn24" id="_ednref24">[24]</a></p>
      <p>Okrem Ruska a Číny nezostali ani menšie komunistické režimy pozadu v zapájaní sa do absolútneho zla. Kambodžská
        genocída bola najextrémnejším incidentom masového vraždenia komunistického štátu.&nbsp; Podľa rôznych odhadov sa
        počet Kambodžanov zabitých režimom Pol Potových Červených Khmérov pohybuje od 1,4 do 2,2 milióna, čo
        predstavovalo jednu tretinu vtedajšej kambodžskej populácie.</p>
      <p>Medzi rokmi 1948 a 1987 zabili severokórejskí komunisti vyše 1 milión svojich vlastných ľudí nútenými prácami,
        pri popravách a uväznením v koncentračných táboroch. Podľa odhadov založených na údajoch zo severokórejského
        sčítania ľudu zabil hladomor v 90. rokoch 20. storočia najmenej 220 000 ľudí.&nbsp; Celkovo na základe
        severokórejských údajov zomrelo v rokoch 1993 až 2008 na neprirodzené úmrtia 600 000 až 850 000 ľudí.<a
          href="#_edn25" id="_ednref25">[25]</a> Podľa iných odhadov sa skutočný počet osôb, ktoré boli
        zabité&nbsp; hladomorom, pohybuje medzi 1 miliónom a 3,5 miliónmi. Potom, čo sa Kim Čong-un dostal k moci,
        spáchal ďalšie vraždy, vrátane vrážd vysokých úradníkov a svojich vlastných príbuzných. Kim tiež ohrozuje svet
        jadrovou vojnou.</p>
      <p>Počas iba jedného storočia od vzostupu prvého komunistického režimu v Rusku zavraždil&nbsp; duch komunizmu v
        krajinách, kde vládol, viac ľudí, než bolo obetí v oboch svetových vojnách. História komunizmu je históriou
        vraždenia a každá stránka je písaná krvou jeho obetí.</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p><a href="#_ednref1" id="_edn1">[1]</a> US Congress, House, „Remembering the Victims of Communism,“ remarks by
        Rep. Christopher Smith, 115th Congress, 1st sess., <em>Congressional Record</em> 163, 13. novembra 2017, <a
          href="https://www.congress.gov/congressional-record/2017/11/13/extensions-of-remarks-section/article/E1557-2"></a><a
          href="https://www.congress.gov/congressional-record/2017/11/13/extensions-of-remarks-section/article/E1557-2">https://www.congress.gov/congressional-record/2017/11/13/extensions-of-remarks-section/article/E1557-2</a>.
      </p>
      <p><a href="#_ednref2" id="_edn2">[2]</a> Stéphane Courtois et al., eds., <em>The Black Book of Communism: Crimes,
        Terror, Repression („</em>Čierna kniha komunizmu: zločiny, teror, represia<em>“),</em> trans. Jonathan Murphy
        and Mark Kramer (Cambridge, MA: Harvard University Press, 1999).</p>
      <p><a href="#_ednref3" id="_edn3">[3]</a> Richard Pipes, <em>The Russian Revolution</em> (New York: Vintage Books,
        1991), 411.</p>
      <p><a href="#_ednref4" id="_edn4">[4]</a> Winston Churchill, <em>The World Crisis, Vol. 5: The Unknown
        War</em> (London: Bloomsbury Academic, 2015).</p>
      <p><a href="#_ednref5" id="_edn5">[5]</a> Robert Service, <em>Lenin, a Biography</em> (Cambridge, MA.: Harvard
        University Press, 2000), 365.</p>
      <p><a href="#_ednref6" id="_edn6">[6]</a> Courtois et al., eds., <em>The Black Book,</em> 177.</p>
      <p><a href="#_ednref7" id="_edn7">[7]</a> Robert Gellately, <em>Lenin, Stalin, and Hitler: The Age of Social
        Catastrophe</em> (New York: Knopf Publishing Group, 2007), 75.</p>
      <p><a href="#_ednref8" id="_edn8">[8]</a> „Zhongguo Gongchandang da shiji. 1945 nian ”中国 共产党 大事记 · 1945 年 (“
        Kronika kľúčových udalostí Čínskej komunistickej strany 1945 ”), Správy Komunistickej strany Číny, zo 16. apríla
        2020, <a href="http://cpc.people.com.cn">http://cpc.people.com.cn</a> /GB/64162/64164/4416000.html. (v čínštine)
      </p>
      <p><a href="#_ednref9" id="_edn9">[9]</a> Frank Dikötter, <em>The Tragedy of Liberation: A History of the Chinese
        Revolution 1945–1957</em> (London: Bloomsbury Press, 2013).</p>
      <p><a href="#_ednref10" id="_edn10">[10]</a> Martin Amis, <em>Koba the Dread: Laughter and the Twenty
        Million</em> (New York: Vintage Books, 2003).</p>
      <p><a href="#_ednref11" id="_edn11">[11]</a> Roy Medvedev, <em>Let History Judge: The Origins and Consequences of
        Stalinism,</em> trans. George Shriver (New York: Columbia University Press, 1989), 240–245.</p>
      <p><a href="#_ednref12" id="_edn12">[12]</a> Aleksandr Solzhenitsyn, <em>The Gulag Archipelago 1918–1956: An
        Experiment in Literary Investigation, Books I–II,</em> trans. Thomas P. Whitney (New York: Harper &amp; Row,
        1973).</p>
      <p><a href="#_ednref13" id="_edn13">[13]</a> Medvedev, <em>Let History Judge,</em> 396.</p>
      <p><a href="#_ednref14" id="_edn14">[14]</a> Reuters, “4.2 Million Were Victims of Purges, KGB Chief Says,” <em>Los
        Angeles Times,</em> June 15, 1991, <a
        href="https://www.latimes.com/archives/la-xpm-1991-06-15-mn-496-story.html"></a><a
        href="https://www.latimes.com/archives/la-xpm-1991-06-15-mn-496-story.html">https://www.latimes.com/archives/la-xpm-1991-06-15-mn-496-story.html</a>.
      </p>
      <p><a href="#_ednref15" id="_edn15">[15]</a> Alexander Yakovlev, <em>Yakeliefu fangtan lu
        1992–2005</em> 雅科夫列夫訪談錄（1992–2005） [<em>Alexander Yakovlev: Selected interviews (1992–2005)</em>], trans.
        Chinese Academy of Social Sciences, 234. (v čínštine)</p>
      <p><a href="#_ednref16" id="_edn16">[16]</a> Wen Yuluo 遇罗文, “Daxing tusha diaocha” 大兴屠杀调查 [“An Investigation of
        the Beijing Daxing Massacre”] in <em>Wen Ge da tusha</em> 文革大屠殺 [<em>Massacres in the Cultural Revolution</em>],
        ed. Song Yongyi 宋永毅 (Hong Kong: Kaifang zazhishe, 2002), 13–36. (v čínštine)</p>
      <p><a href="#_ednref17" id="_edn17">[17]</a> R. J. Rummel, <em>China’s Bloody Century: Genocide and Mass Murder
        Since 1900</em> (New York: Routledge, 2017), 253. (v čínštine)</p>
      <p><a href="#_ednref18" id="_edn18">[18]</a> Dong Baoxun 董宝训 and Ding Longjia 丁龙嘉, <em>Chen yuan zhao yun—pingfan
        yuan jia cuo an</em> 沉冤昭雪—平反冤假錯案 [Exonerate the Innocent: Rehabilitate the Wrongly Accused and Sentenced]
        (Hefei: Anhui Renmin Chubanshe, 1998), 1. (v čínštine)</p>
      <p><a href="#_ednref19" id="_edn19">[19]</a> Oriana Fallaci, “Deng: Cleaning Up Mao’s ‘Feudal Mistakes,’” <em>The
        Washington Post,</em> August 31, 1980, <a
        href="https://www.washingtonpost.com/archive/opinions/1980/08/31/deng-cleaning-up-maos-feudal-mistakes/4e684a74-8083-4e43-80e4-c8d519d8b772"></a><a
        href="https://www.washingtonpost.com/archive/opinions/1980/08/31/deng-cleaning-up-maos-feudal-mistakes/4e684a74-8083-4e43-80e4-c8d519d8b772">https://www.washingtonpost.com/archive/opinions/1980/08/31/deng-cleaning-up-maos-feudal-mistakes/4e684a74-8083-4e43-80e4-c8d519d8b772</a>.
      </p>
      <p><a href="#_ednref20" id="_edn20">[20]</a> Ding Longjia 丁龙嘉 and Ting Yu 听雨, <em>Kang Sheng yu Zhao Jianmin
        yuan’an</em> 康生与赵健民冤案 [<em>Kang Sheng and the Unjust Case of Zhao Jianmin</em>] (Beijing: Renmin Chubanshe,
        1999), as referenced in Hu Angang, <em>Mao and the Cultural Revolution</em>, ed. W. H. Hau (Honolulu: Enrich
        Professional Publishing, Inc., 2016), 2:98.</p>
      <p><a href="#_ednref21" id="_edn21">[21]</a> David Matas and David Kilgour, <em>Bloody Harvest: The Killing of
        Falun Gong for Their Organs</em> (Ontario: Seraphim Editions, 2009), 13.</p>
      <p><a href="#_ednref22" id="_edn22">[22]</a> US Congress, House, <em>Expressing concern regarding persistent and
        credible reports of systematic, state-sanctioned organ harvesting from non-consenting prisoners of conscience in
        the People’s Republic of China, including from large numbers of Falun Gong practitioners and members of other
        religious and ethnic minority groups,</em> HR 343, 114th Cong., 2nd sess., predložené kongresu 25. júna 2015, <a
        href="https://www.congress.gov/bill/114th-congress/house-resolution/343"></a><a
        href="https://www.congress.gov/bill/114th-congress/house-resolution/343">https://www.congress.gov/bill/114th-congress/house-resolution/343</a>.
      </p>
      <p><a href="#_ednref23" id="_edn23">[23]</a> China Tribunal: Independent Tribunal into Forced Organ Harvesting
        from Prisoners of Conscience in China, “China Tribunal: Final Judgment 17th June,” March 1, 2020, <a
          href="https://chinatribunal.com/final-judgment"></a><a
          href="https://chinatribunal.com/final-judgment">https://chinatribunal.com/final-judgment</a>.</p>
      <p><a href="#_ednref24" id="_edn24">[24]</a> Courtois et al., eds., <em>The Black Book,</em> 4.</p>
      <p><a href="#_ednref25" id="_edn25">[25]</a> Thomas Spoorenberg and Daniel Schwekendiek, “Demographic Changes in
        North Korea: 1993–2008,” <em>Population and Development Review,</em> March 21, 2012, accessed via Wiley Online
        Library, <a href="https://onlinelibrary.wiley.com/doi/abs/10.1111/j.1728-4457.2012.00475.x"></a><a
          href="https://onlinelibrary.wiley.com/doi/abs/10.1111/j.1728-4457.2012.00475.x">https://onlinelibrary.wiley.com/doi/abs/10.1111/j.1728-4457.2012.00475.x</a>.
      </p>
      <Link to={`/kapitola_04`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">ďalšia kapitola</span>
      </Link>
      <Link to={`/`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">späť na hlavnú stránku</span>
      </Link>
    </ContentPageWrapper>
  );
};


